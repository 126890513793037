<template>
  <el-dialog v-model="dialog_opened" :close="reset" width="90%">
    <el-form v-if="image" ref="differences" label-width="30px" :model="differences">
      <el-form-item>
        <div style="display: flex; justify-content: space-between; width: 100%; margin-top: 5px;">
          <div style="width: 48%; margin-top: 5px;">
            <el-image lazy :src="`${$CDN_URL}/${image.origin}`" style="width: 100%; height: auto;" @load="handleImageLoad"/>
            <div id="difference-image" style="position: relative; display: inline-block;margin-top: 5px;" @click="handleImageClick">
              <el-image lazy :src="`${$CDN_URL}/${image.origin}`"  class="image-origin"  style="width: 100%;height: auto;"/>
              <el-image lazy :src="`${$CDN_URL}/${image.differences}`" class="image-differences" style="width: 100%; height: auto;"/>
              <div v-if="differences.circles" v-for="(circle, key) in differences.circles" :key="key">
                <div v-if="circle.visible" :style="{ position: 'absolute', top: (circle.y - circle.radius) + 'px', left: (circle.x - circle.radius) + 'px',
                    width: 2 * circle.radius + 'px', height: 2 * circle.radius + 'px', borderRadius: '50%', border: '3px solid red', zIndex: 100}">
                </div>
              </div>
            </div>
          </div>
          <div style="width: 52%; margin-top: 5px; margin-left: 5px">
            <div v-if="differences.config" style="display: flex; margin-bottom: 20px; align-items: center;">
              <label style="width: 90px; margin-right: 5px;margin-left: 15px; align-self: flex-start;">服务端控制：</label>
              <el-switch v-model="differences.config['remote_control']"
                  :inactive-value="false" :active-value="true" :disabled="loading" style="margin-left: 30px; align-self: flex-start;"></el-switch>
            </div>
            <div v-for="(region, index) in differences.regions" style="display: flex; margin-bottom: 20px; align-items: center;">
                <label style="width: 100px; margin-right: 0;margin-left: 15px; align-self: flex-start;">不同点 {{index}}：</label>
                <div style="position: relative; width: 50px;" @click="handleDiffClick(index)">
                  <el-image :src="`data:image/jpeg;base64,${region.image}`" style="width: 50px; height: auto; max-height: 100px;"/>
                  <div v-if="differences.circles && differences.circles[index].visible" style="border: 2px solid red; position: absolute; width: 100%; height: 100%; top: 0; left: 0;"></div>
                </div>
                <el-radio-group v-model="differences.regions[index].difficulty" :disabled="loading"
                                style="margin-left: 30px; align-self: flex-start;">
                  <el-radio :label="1" style="font-weight: normal">简单</el-radio>
                  <el-radio :label="2" style="font-weight: normal">中等</el-radio>
                  <el-radio :label="3" style="font-weight: normal">困难</el-radio>
                </el-radio-group>
                <div style="display: flex; flex-direction: column; width: 100px; align-self: flex-start;">
                  <label style="width: 90px; margin-right: 5px;margin-left: 25px; align-self: flex-start;">是否打开：</label>
                  <el-switch
                      :model-value="differences.regions[index].disabled === undefined || !differences.regions[index].disabled" @update:model-value="(value) => toggleEnabled(index, value)"
                      :inactive-value="false" :active-value="true" :disabled="loading" style="margin-left: 30px; align-self: flex-start;"></el-switch>
                </div>
                <div style="display: flex; flex-direction: column; width: 100px; align-self: flex-start;">
                  <label style="width: 90px; margin-right: 5px;margin-left: 15px; align-self: flex-start;">强制选中：</label>
                  <el-switch
                      :model-value="differences.regions[index].selected || false" @update:model-value="(value) => toggleSelected(index, value)"
                      :inactive-value="false" :active-value="true" :disabled="loading" style="margin-left: 30px; align-self: flex-start;"></el-switch>
                </div>
              </div>
          </div>
        </div>
        <div style="display: flex; justify-content: left; margin-top: 5px;">
          <el-image lazy :src="`${$CDN_URL}/${image.differences}`" style="width: 50%; height: auto;"/>
        </div>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button type="primary" :loading="loading" text @click="reset">取消</el-button>
      <el-button type="primary" :loading="loading" @click="submitDifferences">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import axios from "ts-axios-new";

export default {
    name: "Differences",
    data() {
        return {
            loading: false, dialog_opened: false, differences: {}, image: null,
            imageWidth : 0, imageHeight : 0, displayWidth : 0, displayHeight : 0,
        }
    },
    methods: {
        open(image, new_level=false) {
            this.dialog_opened = this.loading = true;
            this.new_level = new_level
            if(this.image != null && this.image.id !== image.id) {
              this.imageWidth = 0
              this.imageHeight = 0
            }
            this.image = image;
            this.differences = {
              regions: {},
            }
            this.loadDifferences(image)
        },
        submitDifferences() {
          this.loading = true;
          const image = this.image
          axios.post(`/cms/v1/image/${this.image.id}/diff?new_level=${this.new_level}`, {regions: this.differences.regions, config: this.differences.config}).then(res => {
            this.loading = false;
            if(this.image && image.id === this.image.id) {
              this.image.resource = res.data.data.image.resource
              this.image.reviewed = true
            }
            this.reset();
          })
        },
        loadDifferences(image) {
          this.loading = true
          axios.get(`/cms/v1/image/${image.id}/diff?new_level=${this.new_level}`).then(res => {
            this.loading = false
            if(this.image && image.id === this.image.id) {
              this.differences.config = res.data.data.config
              this.differences.regions = res.data.data.diffList
              for (const index in this.differences.regions) {
                let region = this.differences.regions[index]
                if (!region.hasOwnProperty('difficulty')) {
                  region.difficulty = 1
                }
              }
              if (this.imageWidth > 0 && this.imageHeight > 0) {
                this.loadCircles()
              }
            }
          })
        },
        handleDiffClick(index){
          if(this.differences.circles) {
            this.differences.circles[index].visible = !this.differences.circles[index].visible
          }
        },
        handleImageClick(event){
          const rect = document.getElementById('difference-image').getBoundingClientRect();
          const x = event.clientX - rect.left;
          const y = event.clientY - rect.top;
          if (this.differences.circles) {
            for (const index in this.differences.circles) {
              let circle = this.differences.circles[index];
              let distance = (circle.x - x) * (circle.x - x) + (circle.y - y) * (circle.y - y)
              if (distance < circle.radius * circle.radius) {
                if(circle.visible){
                  continue
                }
                circle.visible = true;
                break;
              }
            }
          }
        },
        handleImageLoad(event){
            const img = event.target;
            this.imageWidth = img.naturalWidth
            this.imageHeight = img.naturalHeight
            this.displayWidth = img.width
            this.displayHeight = img.height
            if(this.differences.regions && Object.keys(this.differences.regions).length !== 0) {
                this.loadCircles()
            }
        },
        loadCircles(){
          this.differences.circles = {}
          let ratioWidth = this.imageWidth/this.displayWidth
          let ratioHeight = this.imageHeight/this.displayHeight
          for (const index in this.differences.regions) {
            let circle = {}
            let region = this.differences.regions[index]
            let left = parseInt(region.x / ratioWidth)
            let top = parseInt(region.y / ratioHeight)
            let width = parseInt(region.width / ratioWidth)
            let height = parseInt(region.height / ratioHeight)
            circle.x = parseInt(left + width/2)
            circle.y = parseInt(top + height/2)
            circle.radius = Math.max(width, height)
            circle.visible = false
            this.differences.circles[index] = circle
          }
        },
        toggleEnabled(index, value) {
            this.differences.regions[index].disabled = !value;
        },
        toggleSelected(index, value) {
          this.differences.regions[index].selected = value;
        },
        reset() {
          if(this.$refs.form){
            this.$refs.form.resetFields();
          }
          this.loading = this.dialog_opened = false;
          this.differences = {}
        },
    },
}
</script>

<style scoped>

.image-origin {
  width: auto;
  height: auto;
}

.image-differences {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: auto;
  z-index: 1;
}

.el-radio-group {
  display: flex;
}

.el-radio-group .el-radio {
  margin-right: 18px; /* 调整右侧间距 */
}

</style>