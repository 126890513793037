<template>
    <div>
        <el-button type="primary" :disabled="loading" @click="openDialog()">新建</el-button>
    </div>
    <el-table :data="data" v-loading="loading" style="margin-top: 10px">
        <el-table-column label="名称" width="100" prop="name"></el-table-column>
        <el-table-column label="ID" width="100" prop="id"></el-table-column>
        <el-table-column label="封面" width="200" prop="cover">
            <template #default="scope">
                <el-image v-if="scope.row.cover" :src="`${$CDN_URL}/${scope.row.cover}`" lazy
                          style="width: 180px"></el-image>
            </template>
        </el-table-column>
        <el-table-column label="上线" prop="active" width="100">
          <template #default="scope">
            <el-switch v-model="scope.row.active" :inactive-value="false" :active-value="true"
                       @change="update(scope.row)" :disabled="loading"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="300">
            <template #default="scope">
                <el-button type="success" circle icon="Top" :loading="loading" size="small"
                           :disabled="scope.$index===0" @click="moveUp(scope.$index)"></el-button>
                <el-button type="success" circle icon="Bottom" :loading="loading" size="small"
                           :disabled="scope.$index===data.length - 1" @click="moveDown(scope.$index)"></el-button>
                <el-button size="small" :disabled="loading" @click="openDialog(scope.row)">编辑</el-button>
                <el-button type="success" size="small" :disabled="loading" @click="openTheme(scope.row)">内容
                </el-button>
                <el-button type="danger" size="small" :disabled="loading" @click="remove(scope.row)">删除</el-button>
            </template>
        </el-table-column>
    </el-table>
    <theme-image ref="themeImage"></theme-image>
    <el-dialog v-model="dialog_opened" :before-close="reset">
        <el-form :model="form" ref="form" label-width="100px">
            <el-form-item label="名称" prop="name" :rules="[{required: true, message: '请输入主题名'}]">
                <el-input v-model="form.name" :disabled="!!(loading || editing)"></el-input>
            </el-form-item>
            <el-form-item label="封面" prop="cover" :rules="[{required: true, message: '请上传封面'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleSuccess"
                           accept="image/*" :data="{prefix: 'thumbnail'}" :onProgress="handleProgress"
                           :before-upload="beforeUpload">
                    <img v-if="form.cover" :src="`${$CDN_URL}/${form.cover}`"
                         style="width: 200px">
                    <el-button size="small" v-else :disabled="loading">上传</el-button>
                </el-upload>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button :loading="loading" @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import ThemeImage from "./ThemeImage.vue";
import {update} from "../libs/utils";
import {ElMessage, ElMessageBox} from 'element-plus'

export default {
    name: "LevelTheme",
    components: {ThemeImage},
    data() {
        return {
            loading: false, data: [], dialog_opened: false, editing: null,
            // major_list: [], minor_list: [], assistant_list: [],
            // major_map: {}, minor_map: {}, assistant_map: {},
            form: {
                name: '',
                cover: null,
                active: false,
            },
        }
    },
    // provide() {
    //     return {
    //         major_map: this.major_map, minor_map: this.minor_map, assistant_map: this.assistant_map,
    //     }
    // },
    methods: {
        init() {
            this.loading = true;
            axios.get(`/cms/v1/level-theme`).then(res => {
                this.data = res.data.data.themeList;
                this.loading = false;
            })
        },
        openTheme(theme) {
            this.$refs.themeImage.init(theme);
        },
        df(map, list) {
            list.forEach(l => {
                map[l.id] = l.name;
                if (l.children.length) {
                    this.df(map, l.children);
                }
            });
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    this.editing = item;
                    update(this.form, item);
                }
            })
        },
        reset() {
            if (this.$refs.form) {
              this.$refs.form.resetFields();
            }
            this.loading = this.dialog_opened = false;
            this.editing = null;
        },
        handleSuccess(res) {
            this.form.cover = res.data.name;
            this.loading = false;
        },
        handleProgress() {
            this.loading = true;
        },
        beforeUpload(file) {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = e => {
              const image = new Image();
              image.src = e.target.result;
              image.onload = _ => {
                if (file.size <= 500000) {
                  resolve();
                } else {
                  reject();
                }
              }
            }
          }).then(_ => {
            return file;
          }, _ => {
            ElMessage.error('图片大小500K以下');
            return Promise.reject()
          })
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/level-theme/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset();
                        })
                    } else {
                        axios.post(`/cms/v1/level-theme`, this.form).then(res => {
                            this.data.unshift(res.data.data)
                            this.reset();
                        })
                    }
                }
            })
        },
        update(row) {
          axios.put(`/cms/v1/level-theme/${row.id}`, row).then(_ => {
          })
        },
        moveUp(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index - 1, 0, row[0]);
            this.resort();
        },
        moveDown(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index + 1, 0, row[0]);
            this.resort();
        },
        resort() {
            const sort = [];
            this.data.forEach(d => {
                sort.push(d.id);
            })
            axios.post(`/cms/v1/sort/level-theme`, {sort}).then(_ => {
            })
        },
        remove(row) {
            ElMessageBox.confirm('确定要删除该项吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: "warning",
            }).then(_ => {
                this.loading = true;
                axios.delete(`/cms/v1/level-theme/${row.id}`).then(res => {
                    this.data.splice(this.data.indexOf(row), 1);
                    this.loading = false;
                })
            }).catch(_ => {})
        },
    },
    mounted() {
        // axios.all([
        //     axios.get('/cms/v1/tag/major').then(res => {
        //         this.major_list = res.data.data.tagList;
        //         this.df(this.major_map, this.major_list);
        //     }),
        //     axios.get('/cms/v1/tag/minor').then(res => {
        //         this.minor_list = res.data.data.tagList;
        //         this.df(this.minor_map, this.minor_list);
        //     }),
        //     axios.get('/cms/v1/tag/assistant').then(res => {
        //         this.assistant_list = res.data.data.tagList;
        //         this.df(this.assistant_map, this.assistant_list);
        //     }),
        // ]).then(_ => {
            this.init();
        // })
    },
}
</script>

<style scoped>

</style>