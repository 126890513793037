<template>
    <div style="display: flex;flex-wrap: wrap;">
        <el-select v-model="query.type" clearable filterable placeholder="道具类型" @change="init" style="width: 120px">
          <el-option v-for="t in this.prop_types" :label="t" :value="t"></el-option>
        </el-select>
        <el-button type="primary" :disabled="loading" @click="openDialog()" style="margin-left: 5px">新建</el-button>
    </div>
    <el-table :data="data" v-loading="loading" style="margin-top: 10px">
        <el-table-column label="ID" width="100" prop="id"></el-table-column>
        <el-table-column label="类型" width="100" prop="type"></el-table-column>
        <el-table-column label="子类型" width="100" prop="subtype"></el-table-column>
        <el-table-column label="名字" width="100" prop="name"></el-table-column>
        <el-table-column label="图片" width="200" prop="image">
            <template #default="scope">
                <el-image v-if="scope.row.image" :src="`${$CDN_URL}/${scope.row.image}`" lazy
                          style="width: 180px"></el-image>
            </template>
        </el-table-column>
        <el-table-column label="上线" prop="active" width="100">
          <template #default="scope">
            <el-switch v-model="scope.row.active" :inactive-value="false" :active-value="true"
                       @change="update(scope.row)" :disabled="loading"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="300">
            <template #default="scope">
                <el-button type="success" circle icon="Top" :loading="loading" size="small"
                           :disabled="scope.$index===0" @click="moveUp(scope.$index)"></el-button>
                <el-button type="success" circle icon="Bottom" :loading="loading" size="small"
                           :disabled="scope.$index===data.length - 1" @click="moveDown(scope.$index)"></el-button>
                <el-button size="small" :disabled="loading" @click="openDialog(scope.row)">编辑</el-button>
                <el-button type="danger" size="small" :disabled="loading" @click="remove(scope.row)">删除</el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-dialog v-model="dialog_opened" :before-close="reset">
        <el-form :model="form" ref="form" label-width="100px">
            <el-form-item label="类型" prop="type" :rules="[{required: true, message: '请输入道具类型'}]">
              <el-select v-model="form.type" filterable allow-create :disabled="loading">
                <el-option v-for="s in prop_types" :value="s"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="价格" prop="cost" :rules="[{required: false, message: '请输入价格'}]">
              <el-input-number v-model="form.cost" :disabled="loading"></el-input-number>
            </el-form-item>

            <el-form-item label="名称" prop="name" :rules="[{required: true, message: '请输入名称'}]">
              <el-input v-model="form.name" :disabled="loading"></el-input>
            </el-form-item>

            <el-form-item label="服饰类型" prop="type" :rules="[{required: true, message: '请输入服饰类型'}]">
              <el-select v-model="form.subtype" filterable allow-create :disabled="loading">
                <el-option v-for="s in subtypeList" :value="s"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="图片" prop="image" :rules="[{required: true, message: '请上传道具图片'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleSuccess"
                           accept="image/*" :data="{prefix: 'thumbnail'}" :onProgress="handleProgress"
                           :before-upload="beforeUpload">
                    <img v-if="form.image" :src="`${$CDN_URL}/${form.image}`"
                         style="width: 200px">
                    <el-button size="small" v-else :disabled="loading">上传</el-button>
                </el-upload>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button :loading="loading" @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import {update} from "../../libs/utils";
import {ElMessage, ElMessageBox} from 'element-plus'

export default {
    name: "Prop",
    components: {},
    data() {
        return {
            loading: false, data: [], dialog_opened: false, editing: null,
            prop_types: ["Food", "Clothes", "Toy"],
            clothes_types: ["Hat"],
            // major_list: [], minor_list: [], assistant_list: [],
            // major_map: {}, minor_map: {}, assistant_map: {},
            query: {
              type: null,
            },
            form: {
                type: null,
                image: null,
                cost: 0,
                subtype: null,
                active: false,
                name: "",
            },
        }
    },
    // provide() {
    //     return {
    //         major_map: this.major_map, minor_map: this.minor_map, assistant_map: this.assistant_map,
    //     }
    // },
    methods: {
        init() {
            this.loading = true;
            axios.get(`/cms/v1/prop`, {params: this.query}).then(res => {
                this.data = res.data.data.list;
                this.loading = false;
            })
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    this.editing = item;
                    update(this.form, item);
                }
            })
        },
        reset() {
            if (this.$refs.form) {
              this.$refs.form.resetFields();
            }
            this.loading = this.dialog_opened = false;
            this.editing = null;
            this.form.subtype = null;
            this.form.type = null
            this.form.cost = 0
            this.form.active = false
            this.form.image = null
            this.form.name = null
        },
        handleSuccess(res) {
            this.form.image = res.data.name;
            this.loading = false;
        },
        handleProgress() {
            this.loading = true;
        },
        beforeUpload(file) {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = e => {
              const image = new Image();
              image.src = e.target.result;
              image.onload = _ => {
                if (file.size <= 500000) {
                  resolve();
                } else {
                  reject();
                }
              }
            }
          }).then(_ => {
            return file;
          }, _ => {
            ElMessage.error('图片大小500K以下');
            return Promise.reject()
          })
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/prop/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset();
                        })
                    } else {
                        axios.post(`/cms/v1/prop`, this.form).then(res => {
                            this.data.unshift(res.data.data)
                            this.reset();
                        })
                    }
                }
            })
        },
        update(row) {
          axios.put(`/cms/v1/prop/${row.id}`, row).then(_ => {
          })
        },
        moveUp(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index - 1, 0, row[0]);
            this.resort();
        },
        moveDown(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index + 1, 0, row[0]);
            this.resort();
        },
        resort() {
            const sort = [];
            this.data.forEach(d => {
                sort.push(d.id);
            })
            axios.post(`/cms/v1/sort/prop`, {sort}).then(_ => {
            })
        },
        remove(row) {
            ElMessageBox.confirm('确定要删除该项吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: "warning",
            }).then(_ => {
                this.loading = true;
                axios.delete(`/cms/v1/prop/${row.id}`).then(res => {
                    this.data.splice(this.data.indexOf(row), 1);
                    this.loading = false;
                })
            }).catch(_ => {})
        },
    },
    computed: {
      subtypeList() {
        return this.form.type === 'Clothes' ? this.clothes_types : [];
      },
    },
    mounted() {
        // axios.all([
        //     axios.get('/cms/v1/tag/major').then(res => {
        //         this.major_list = res.data.data.tagList;
        //         this.df(this.major_map, this.major_list);
        //     }),
        //     axios.get('/cms/v1/tag/minor').then(res => {
        //         this.minor_list = res.data.data.tagList;
        //         this.df(this.minor_map, this.minor_list);
        //     }),
        //     axios.get('/cms/v1/tag/assistant').then(res => {
        //         this.assistant_list = res.data.data.tagList;
        //         this.df(this.assistant_map, this.assistant_list);
        //     }),
        // ]).then(_ => {
            this.init();
        // })
    },
}
</script>

<style scoped>

</style>